@font-face {
  font-family: Lora-web;
  src: url("./fonts/Lora-Regular.woff2");
}

@font-face {
  font-family: Roboto-web;
  font-weight: 400;
  src: url("./fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: Alex-Brush-web;
  font-weight: 400;
  src: url("./fonts/AlexBrush-Regular.ttf");
}

@font-face {
  font-family: Roboto-web;
  font-weight: 500;
  src: url("./fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: Roboto-web;
  font-weight: 800;
  src: url("./fonts/Roboto-Bold.woff2");
}

@font-face {
  font-family: Roboto-web;
  font-weight: 900;
  src: url("./fonts/Roboto-Black.woff2");
}

@font-face {
  font-family: Courgette-web;
  src: url("./fonts/Courgette-Regular.ttf");
}



* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

:root {
  --_font-color: #fff;
  --_background: transparent;
  --_font-color-inverse: #000;

  /* widths */
  --component-width: 80%;
  --component-margin: auto;
  --transform-animation-below: translateY(5rem);

  --transform-no-animation: translate(0,0);

  /* font-sizes */
  --title-font-size: 5rem;
  --p-font-size: .95rem;

  /* font-weight */
  --title-font-weight: 900;
  --p-font-weight: 400;

  /* font-style */
  --title-font-family: Roboto-web;
}

html, body, #root {
  margin: 0;
  border: 0;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@keyframes typing-animation-slider {
  to {
      left: 100%;
      display: none;
  }
}

@media screen and (max-width: 1024px) {
  :root {
    /* font-sizes */
    --title-font-size: 3.5rem;
    --p-font-size: .85rem;
  }
}

@media screen and (max-width:768px) {
  :root {
    /* font-sizes */
    --title-font-size: 3rem;
    --p-font-size: .8rem;
  }
}

@media screen and (max-width:425px) {
  :root {
    /* container-size */
  --component-width: 90%;

    /* font-sizes */
    --title-font-size: 2rem;
    --p-font-size: .8rem;
  }
}
